/* You can add global styles to this file, and also import other style files */

// // fonts
@import url('https://fonts.googleapis.com/css2?family=Nunito:wght@200;300;400;600;700;800;900&display=swap');
$mainfont : 'Nunito', sans-serif;
@import url('https://fonts.googleapis.com/css2?family=Mulish:wght@200;300;400;500;600;700;800;900&display=swap');
$mulish : 'Mulish', sans-serif;
@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@300;500;700;900&family=Mulish:wght@200;300;400;600;700;900&family=Roboto:wght@300;400;500;700;900&display=swap');


$bg-failure: #eb4d4b;
$bg-success: #6ab04c;
$bg-pending: rgba(189,100,0,0.6);

// Theme Colours
$themecolor1: #e9eaee; // header
$themeaccentcolor1: #814495;
$themecolor2: #f5f5f5; // background
$themecolor3: #dfe4ea; // pagetitle
$themecolor4: #ffffff; // table-container
$themecolor5: #535c68; // menu

$menudividerlight: #f1f2f6;
$menudividerdark: #ced6e0;
$submenudividerlight: #8f51a4;
$submenudividerdark: #6b377c;

$buttoncolor1: #eb4d4b;
$buttonaccentcolor1: #ff7979;
$buttoncolor2: #f1f2f6;

$titlecolor1: #57606f;
$titlecolor2: #227093;

// dark theme
// $themecolor1: #aaa69d;
// $themecolor2: #f7f1e3;



/*
  1. Use a more-intuitive box-sizing model.
*/
*, *::before, *::after {
  box-sizing: border-box;
  // outline: 1px solid #ff0000;
}
/*
  2. Remove default margin
*/
* {
  margin: 0;
}
/*
  3. Allow percentage-based heights in the application
*/
html, body {
  height: 100%;
  background: #F7F7F7;
}
/*
  Typographic tweaks!
  4. Add accessible line-height
  5. Improve text rendering
*/
body {
  line-height: 1.5;
  -webkit-font-smoothing: antialiased;
  font-family: $mainfont;
}
/*
  6. Improve media defaults
*/
img, picture, video, canvas, svg {
  display: block;
  max-width: 100%;
}
/*
  7. Remove built-in form typography styles
*/
input, button, textarea, select {
  font: inherit;
}
/*
  8. Avoid text overflows
*/
p, h1, h2, h3, h4, h5, h6 {
  overflow-wrap: break-word;
}
/*
  9. Create a root stacking context
*/
#root, #__next {
  isolation: isolate;
}

html {height: 100%;}
body {margin: 0px !important; font-family: $mainfont, sans-serif;}

// Miscellaneous
.toolbarspacer {flex: 1 1 auto;}
.clear {clear: both;}

// Button Styles
.redbtn {background: $buttoncolor1;color: #fff;}
.redbtn:hover {color: #fff;background: $buttonaccentcolor1;}
.greybtn {background: $buttoncolor2;color: $titlecolor1;}
.greybtn:hover {color: $titlecolor1;}
.filterbtn {background: transparent !important;color: $titlecolor1;}

// Remove browser unwanted styles
input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active  {
    box-shadow: 0 0 0 30px $themecolor2 inset !important;
    -webkit-box-shadow: 0 0 0 30px $themecolor2 inset !important;
}

.MuiChip-label { font-family: $mainfont; text-transform: uppercase; font-size: 10px; font-weight: 700; }


.vertical-layout, div.defaultLightbox, div.login, div.login > form  { display: flex; flex: 1; flex-direction: column; }

.horizontal-layout, div.main { display: flex; flex: 1; flex-direction: row; }

.mat-tab-group.mat-primary .mat-ink-bar, .mat-tab-nav-bar.mat-primary .mat-ink-bar {background-color: $themecolor1 !important;}
.mat-ink-bar {height: 4px;}
.mat-tab-label-content {text-transform: uppercase;font-family: $mainfont;}
.mat-tab-labels {background: #fff;}

.aside {
    & {font-family: $mainfont;width: 90%;background: #ecf0f1;padding: 0;}

    .header { width: 100%; padding: 15px 2%; margin: 0; background: #FFF;box-shadow: rgba(0,0,0,0.2); text-transform: uppercase; }
    .content { margin: 20px; text-align: justify; }
    .actionBtn { display: flex; justify-content: flex-end; margin: 20px; }

}

.navbar {

    & {display: flex;flex-direction: column;width: 340px;background: #ced6e0; }

    a {
        & { display: block; width: 80%; margin: 0 auto; color: #FFF;
            text-decoration: none; font-size: 1.2em; padding: 5px 10px; border-bottom: 1px solid #3d8fb6;
            border-top: 1px solid #277499; font-family: $mainfont; }

        &:first-of-type { border-top: none; }

        &:last-of-type { border-bottom: none; }

        &:hover { color: #ecf0f1; }
    }
}

.App { font-family: $mainfont, sans-serif; position: relative; height: 100vh; width: 100vw; }

/* -- Menu Main -- */
/* -- ========= -- */
.menu {

    & { height: 100vh; flex: 0 1 300px;background: $themecolor1;color: #fff; transition: 1s; }
    .company-logo { text-align: center; margin: 10px auto; }
    .company-logo img { max-width: 70%; }

    nav { margin: 20px 12%; }

    nav a {display: flex;width: 100%;margin: 0 auto;color: $titlecolor1;text-decoration: none;font-size: 1em;font-weight: 400;padding: 10px 5px;background: none;border-bottom: 1px solid $menudividerlight;border-top: 1px solid $menudividerdark;}
    nav .MuiSvgIcon-root { margin-top: -2px; margin-right: 10px; }

    nav a:first-of-type {border-top: none;}
    nav a:last-of-type {border-bottom: none;}
    nav a:hover {color: $titlecolor1;}

    .menulogo {height: 32px;margin: 6px 0 2px -20px;}

    /*.container {height: 100%;}
    nav h2 {color: #fff;font-size: 1.5em;text-transform: uppercase;width: 90%;margin: 0 auto;padding: 15px 5%;font-weight: 800;}
    nav h3 {color: #fff;font-size: 1.2em;letter-spacing: 0.1em;text-transform: uppercase;width: 90%;margin: 0 auto;padding: 15px 5%;font-weight: 600;}
    nav a {display: block;width: 90%;margin: 0 auto;color: #fff;text-decoration: none;font-size: 1.1em;font-weight: 300;padding: 15px 5px;background: none;border-bottom: 1px solid $menudividerlight;border-top: 1px solid $menudividerdark;}
    nav .profile {background: $themeaccentcolor1;padding: 20px;text-align: center;font-size: 1.4em;display: flex;align-items: center;}
    nav .profile .profilepic {flex: 0 0 60px;height: 47px;border-radius: 50%;background: $themecolor1;padding-top: 13px;}
    nav .profile .profiledetails {flex: 1 1 auto;font-size: 14px;text-align: left;line-height: 16px;margin-left: 10px;}
    nav .profile .profiledetails span {font-size: 12px;}
    nav .submenu {padding: 5px 5px 5px 20px;font-size: 0.9em;border-top: 1px solid $submenudividerlight;border-bottom: 1px solid $submenudividerdark;}
    nav .sub {background: $themeaccentcolor1;}
    nav a:first-of-type {border-top: none;}
    nav a:last-of-type {border-bottom: none;}
    nav a:hover {color: #ecf0f1;}
    nav a i {display: block;float: left;margin-top: -4px;margin-right: 20px;font-size: 28px;opacity: 0.3;}
    .logo { padding: 15px 10px; width: 90%; border: none; line-height: 0; }
    .logo img { width: 150px; }*/
}

.login-register {
  & { display: flex; flex-wrap: wrap; align-items: center; margin-bottom: 60px; height: 100vh; }
  .login {
    & { margin-top: -60px; }
    .form-heading { color: #718194; font-size: 24px; font-weight: 600; line-height: 32px; text-align: center; }
    .form-wrapper {
      & { margin: 70px 0px 0px; }
      form { margin-bottom: 0px; }
      .MuiFormLabel-root { font-size: 14px; } 
      .MuiOutlinedInput-input { font-size: 14px; }
    }
    .remember-forgot-wrapper {
      & { margin: 10px 0px 30px; display: flex; flex-wrap: wrap; justify-content: space-between; }
      a { color: #007C89; text-decoration: none; font-size: 14px; font-weight: 400; }
    }
    .no-account-wrapper {
      & { margin-top: 20px; color: #292F36; font-size: 16px; font-weight: 400; }
      a { color: #007C89; text-decoration: none; }
    }
    .visibility { cursor: pointer; }

  }

}


.main {
  & { height: 100vh; flex: 1 1 auto;background: $themecolor2; color: $titlecolor1;}
  h1 { color: $titlecolor1; }

  .container { 
    
    & { width: 92%; height: 96vh; padding: 0 4% 4vh; overflow-y: auto; }

    header {       
      & { display: flex; align-items: center; justify-content: space-between; }
      .header-cta { display: block; cursor: pointer; background: #007C89; color: #fff; padding: 6px 14px; border-radius: 3px; text-transform: uppercase; font-weight: 800; font-size: 14px; }
    }

  }

  .MuiTable-root { color: $titlecolor1; font-family: $mainfont, sans-serif; }
  .MuiTableCell-head { color: $titlecolor1; font-weight: 700; font-family: $mainfont, sans-serif; }
  .MuiTableCell-body { color: $titlecolor1; font-family: $mainfont, sans-serif; }


  .table-tool { cursor: pointer; }
}

.drawer {

  & { width: 80vw; }
  header { width: calc(100% - 60px); padding-left: 30px; }

  .MuiTabs-flexContainer { background: #efefef; }

  section { background: #efefef; padding: 30px; min-height: calc(100vh - 130px); width: calc(100% - 60px); overflow-y: scroll; }

  .double-column-form { 
    & { display: flex; align-items: center; }
    .MuiInputBase-fullWidth { width: 98%; }
    #file-type { top: -5px; left: 15px; }
  }

  .margin-top { margin-top: 15px; }
  .aligned-right { justify-content: flex-end; margin-right: 1%; }

}


.container { padding: 0 2rem; margin: 0 auto; min-height: 100vh; position: relative; background-color: #ffffff; }

// buttons
.button { 
    .MuiButtonBase-root { border: 2px solid #dcdcdc; border-radius: 5px; width: 150px; padding: 5px 10px; color: #000; background: #fff; font-family: 'Mulish', system-ui, -apple-system; font-weight: 700; margin-bottom: 60px;}
   
}
.button-fff {  &:hover { color: #fff !important; }  }
.light-blue-btn { 
    .MuiButtonBase-root { border: 2px solid #6f7f9f; border-radius: 5px; padding: 5px 10px; color: #6f7f9f; background: #fff; font-family: 'Mulish', system-ui, -apple-system; font-weight: 700; margin-bottom: 60px;}
}
.blue-btn {
    .MuiButtonBase-root {  box-shadow: 0px 5px 15px 0px #2b1de870; width: 150px; background: #2b1de8; color: #fff; border: 2px solid #2b1de8; margin-bottom: 60px; }
    .MuiButtonBase-root:hover { background: #2b1de8; }
}
.blue-border-btn {
    .MuiButtonBase-root { box-shadow: 0px 5px 15px 0px #10000030; background: #fff; color: #2b1de8; border: 2px solid #2b1de8;  width: 150px; margin-bottom: 60px;  }
}

// advertisement 
//.ad-container { }

// add new term modal
.new-term-container {
    & { position: relative; margin-top: 50px; display: flex; align-items: center; justify-content: space-evenly; }

    .new-term {
        & {text-align: center;}
        .heading { & { margin-bottom: 50px; }
          h1 {font-family: 'Montserrat'; font-weight: 600; margin-bottom: 0;}
          h3 {font-family: 'Mulish'; font-weight: 400; margin-top: 0;}
        }
        .image {
          & { display: flex; align-items: center; justify-content: center; }
        }
        .input {
            & { margin-top: 50px; display: flex; flex-direction: column; align-items: center;}
            label { margin-bottom: 10px; color: #6f7f9f; font-family: 'Mulish'; font-weight: 600;}
            input { text-align: center; width: 530px; }
            .helper-text { font-size: 12px; color: #6f7f9f; font-family: 'Mulish'; font-weight: 600; }
            .warning { color: #ff0000; }
        }
        .btn-close { color: #333; cursor: pointer; position: absolute; top: -55px; right: 0;}
    }

  }

// add new location modal

  .new-location-container {
    & { position: relative; width: 100%; height: 100%; background: #ffffff; display: flex; align-items: center; justify-content: space-evenly; flex-direction: column; }

    .new-location {
      & { height: 80%; width: 100%; text-align: center; }
      .heading { & { margin-bottom: 50px; }
        h1 { font-family: 'Montserrat'; font-weight: 600; margin-bottom: 0; }
        h3 { font-family: 'Mulish'; font-weight: 400; margin-top: 0; }
      }
    }
    .address-title-input {
      & { width: 100%; display: flex;  align-items: center; justify-content: center; gap: 10px; }
      .input {
        & { display: flex; align-items: flex-start; justify-content: center; flex-direction: column; }
        label { color: #6f7f9f; }
        input {
          & { width: 300px; border: none; border-bottom: 2px solid #1c2238; padding: 10px; }
          &:focus { outline: none; }
        }
      }
      .input-address {
        & { flex: 1; }
        input { width: 100%; }
      }
      .blue-btn {
        & { cursor: pointer; height: 35px; width: 65px; border-radius: 5px; background: #2b1de8; color: #fff; border: none; margin-top: auto; }
      }
    }

    .btn-close { height: 30px; width: 30px; display: flex; align-items: center; justify-content: center; z-index: 10; padding: 5px; border-radius: 5px; background: #fff; color: #333; cursor: pointer; position: absolute; top: 30px; left: 10px;}

  }



// price plan

  .price-plan-container {
    & { display: flex; flex-direction: column; align-items: center; justify-content: center; padding-top: 3rem; }
    .price-plan-header { & { text-align: center; margin-bottom: 50px;}
        h1 { font-family: 'Roboto', system-ui, -apple-system; font-weight: 600; font-size: 32px; margin-bottom: 0; }
        h3 { font-family: 'Roboto', system-ui, -apple-system; font-weight: 400; font-size: 22px; margin-top: 10px; }
        }
    .price-plan {
      & { display: flex; align-items: center; justify-content: center; gap: 55px; }
        .price-card {
          
          & { box-shadow: 0px 12px 15px 0px rgba(25,0,0,0.2); display: flex; flex-direction: column; align-items: center; justify-content: center; padding: 30px; padding-bottom: 20px; height: 400px; width: 256px; background: #f7f7f7; border-radius: 20px; text-align: center; transition: 250ms ease; }
            &:last-child { box-shadow: 0px 12px 15px 0px  #0094ff65; background-image: url("./assets/img/ad/circle_lights.svg"),  linear-gradient(to bottom, #2b1de8, #0094ff); background-size: cover; transition: 250ms ease; }
            &:hover {transform: scale(1.01);}
            hr {width: 95%;}
            .plan-name {
              h3 { font-family: 'Mulish', system-ui, -apple-system; font-weight: 700; text-transform: uppercase; }
            }
            .plan-duration {
                & {color: #2b1de8;}
                h1 { font-family: 'Roboto', system-ui, -apple-system; font-weight: 500; font-size: 72px; margin: 0; margin-top: -10px; }
                h3 { font-family: 'Montserrat', system-ui, -apple-system; font-weight: 600; font-size: 24px; margin: 0; margin-top: -15px; margin-bottom: 20px; }
              }
              .plan-price {
                p {font-family: 'Mulish', system-ui, -apple-system; font-weight: 700; font-size: 16px; margin: 0; color: #686868; margin-top: 20px;}
                h2 {font-family: 'Roboto', system-ui, -apple-system; font-weight: 500; font-size: 40px; margin: 0; color: #686868; margin-bottom: 20px; }
            }
        }
    }
  }
  

  // Coupon yes or no
  
  .yes-no-container {
    & {width: 100%; height: 100vh; display: flex; position: relative;}
    .yes-no-left {
        & { width: 50%; height: 100%; display: flex; align-items: center; justify-content: center; }
        .yes-no {
            & { text-align: center; display: flex; align-items: center; justify-content: center; flex-direction: column; gap: 1rem; }
            p { font-family: 'Montserrat', system-ui, -apple-system; font-weight: 400; font-size: 24px; }
            .btn-wrapper { display: flex; align-items: center; justify-content: space-evenly; gap: 1rem; }
        }
    }

    .yes-no-right {
        & { width: 55%; background-image: url("./assets/img/ad/YesOrNo.svg"); background-size: cover; background-repeat: no-repeat; }
    }
}

// coupon create

.coupon-select-container {
    & { display: flex; flex-direction: column; align-items: center; justify-content: center; }
    .coupon-select-header { & { text-align: center; margin-bottom: 0; margin-top: 70px;}
        h1 { font-family: 'Roboto', system-ui, -apple-system; font-weight: 600; font-size: 32px; margin-bottom: 0; }
        h3 { font-family: 'Roboto', system-ui, -apple-system; font-weight: 400; font-size: 22px; margin-top: 10px; }
        }
    .coupon-container {
        & { display: flex; align-items: center; justify-content: center; gap: 55px; }
        .coupon-card {
            & { cursor: pointer; position: relative; display: flex; flex-direction: column; align-items: center; justify-content: center; padding: 0 30px; padding-bottom: 20px; border : 2px solid #c4c4c4; height: 400px; width: 300px; background: #f7f7f7; border-radius: 15px; text-align: center; }
            .coupon-head {
                & { display: flex; flex-direction: column; justify-content: center; align-items: center; margin-bottom: 25px; height: 200px; width: 200px; background: url("./assets/img/ad/couponBg.svg"); background-repeat: no-repeat; background-size: cover;}  
                h1 {  margin-bottom: 5px; font-family: 'Roboto'; font-size: 26px; }
                span { font-size: 16px; font-family: 'Mulish'; }
                h2:last-child { margin-top: 10px; margin-bottom: 10px; font-family: 'Mulish'; font-weight: 900; font-size: 20px;}
            }     
            .coupon-inputs {
              & { display: flex; align-items: center; justify-content: center; flex-direction: column; }
                .MuiInputBase-root {margin-bottom: 20px;}
                input {text-align: center;}
                .coupon-bottom-text {font-size: 20px; font-weight: 900; font-family: 'Mulish' }
            }
            .coupon-select { & { position: absolute; bottom: -25px; left: 50%; right: 50%; height: 10px; width: 10px; border: 2px solid #2b1de8; background: transparent; border-radius: 100px; }
        }
        }
    }
}

// coupon create

.coupon-select-container {
    & { display: flex; flex-direction: column; align-items: center; justify-content: center; }
    .coupon-select-header { & { text-align: center; margin-bottom: 10px;}
        h1 { font-family: 'Roboto', system-ui, -apple-system; font-weight: 600; font-size: 32px; margin-bottom: 0; }
        h3 { font-family: 'Roboto', system-ui, -apple-system; font-weight: 400; font-size: 22px; margin-top: 10px; }
        }
    
}


// logo and back link

.deliva-logo { z-index: 100; padding: 0 50px; height: 70px; background: #fff; position: fixed; top: 0; left: 0; right: 0; display: flex; align-items: center; justify-content: space-between; }

.close-coupon { height: 24px; width: 24px; color: #6f7f9f; background: #f5f7fb; border-radius: 5px; cursor: pointer; }
.logo-home { position: absolute; top: 20px; left: 20px; right: 0; background-image: url("./assets/img/ad/deliva_blue_logo.svg"); background-repeat: no-repeat; background-size: contain; height: 70px; width: 70px; }
.logo { background-image: url("./assets/img/ad/deliva_blue_logo.svg"); background-repeat: no-repeat; background-size: contain; height: 60px; width: 70px; }
.back-link-container { 
  & { width: 100%; position: absolute; bottom: 0px; left: 0px; color: #686868; font-weight: bold; text-decoration: none; background-color: #F7F7F7; padding: 15px 10px 15px 50px; }
  .back-link{ display: flex; align-items: center; }
  svg { height: 15px; fill: #686868;}
  span { display: flex; align-items: center; justify-content: center; }
}


// Help

.help-container {
    & { display: flex; align-items: center; justify-content: center; padding: 20px; }

    .help-me {
        & { text-align: center; max-width: 1000px; }
        .help-heading {
            & {margin: 25px 0;}
            h1 { font-family: 'Montserrat', system-ui, -apple-system; font-weight: 700; font-size: 32px; }
            h2 { font-family: 'Mulish', system-ui, -apple-system; font-weight: 400; font-size: 22px; }
        }
        .help-input {
          &{margin: 100px auto;}
            .input-first { & { margin: 50px 0; display: flex; align-items: center; justify-content: space-between; }
            input {width: 350px;}
            }
            .input-second { & { margin: 50px 0; display: flex; align-items: center; justify-content: space-between; }
            input {width: 350px;}
            }
            textarea {background: #f5f7fb; outline: #eee; border: 2px solid #ddd; border-radius: 5px; padding: 5px;}
        }
        .blue-btn {
          & { margin-top: 1rem; display: flex; align-items: center; justify-content: center; }
          .btn {
            & { cursor: pointer; padding: 8px 16px; border: none; background: #2b1de8; color: #FFF; font-size: 18px; font-weight: 500; border-radius: 5px; }
          }
        }
    }
}
// Thanks

.thanks-container {
    & { display: flex; align-items: center; justify-content: center; padding-top: 100px; }

    .thanks {
        & { text-align: center; }
        .thanks-img {
            &{width: 300px; height: 300px; border-radius: 100%; border: 10px solid #fff !important; box-shadow: 0px 12px 15px 0px rgba(25,0,0,0.2); display: flex; justify-content: center; align-items: center; margin: 0 auto 20px;}
            img { height: 75%;  }
        }
        .thanks-text {
            h1 { font-family: 'Montserrat', system-ui, -apple-system; font-weight: 500; font-size: 36px; }
            h3 { font-family: 'Mulish', system-ui, -apple-system; font-weight: 400; font-size: 22px; }
        }
        .light-blue-btn{ &{margin-top: 20px;} }
        
    }
}

// stepper

.stepper {
    & { width: 100%; box-shadow: 10px 0px 15px 0px rgba(25,0,0,0.2); position: fixed; bottom: 0; background: #fff; }
    .stepper-control { & { max-width: 1000px; display: flex; align-items: center; justify-content: space-between; margin: 0 auto; padding: 5px 15px; }
        .next-step { & { text-align: center; }
        p { font-family: "Mulish"; color: #6f7f9f; margin: 0; }
        h3 { font-family: "Mulish"; color: #6f7f9f; margin: 0; }
    }
    }
}

//save and continue
.save-and-continue {
  & { width: 100%; height: 70px; display: flex; align-items: center; justify-content: center; box-shadow: 10px 0px 15px 0px rgba(25,0,0,0.2); position: fixed; bottom: 0; background: #fff; }
  .save-continue-btn {
    & { background: #2b1de8; color: #fff; border: 2px solid #ffffff; padding: 10px; }
    &:hover { background: #ffffff; color: #2b1de8; border: 2px solid #2b1de8; }
  }
}

.css-1be5mm1-MuiLinearProgress-root-MuiMobileStepper-progress {width: 100% !important;}
.MuiLinearProgress-root {width: 100% !important;}
.css-1be5mm1-MuiLinearProgress-root-MuiMobileStepper-progress { width: 100% !important;}
.css-5xe99f-MuiLinearProgress-bar1 {background-color: #2b1de8 !important;}

// steps

.steps {
    & { height: 100%; overflow-y: scroll; }
}

// step1

.step1-container {
    & { margin-top: 50px; display: flex; align-items: center; justify-content: center; }

    .step1
     {
        & { text-align: center; width: 1000px; margin-top: 50px; }
        .step1-heading {
            & {margin: 25px 0;}
            h1 { font-family: 'Montserrat', system-ui, -apple-system; font-weight: 700; font-size: 32px; }
            h2 { font-family: 'Mulish', system-ui, -apple-system; font-weight: 400; font-size: 22px; }
        }
        .step1-input {
            .input-first { & { margin: 50px 0; display: flex; align-items: center; justify-content: space-between; }
            input {width: 350px;}
            }
            .input-second { & { margin: 50px 0; display: flex; align-items: center; justify-content: space-between; }
            input {width: 350px;}
            }
            .input-third { & { margin: 50px 0; display: flex; align-items: center; justify-content: space-between; }
            input {width: 350px;}
            }
        }
    }
}

// step2

.step2-container {
    & { height: 500px; margin-top: 100px; display: flex; align-items: center; justify-content: center;  }

    .step2 {
      & { position: relative; width: 70%; height: 100%; background: #f5f7fb; border: 3px dashed #004fac; border-radius: 20px; text-align: center; display: flex; flex-direction: column; align-items: center; justify-content: space-evenly; }
      .step2-heading {
          & {padding: 0 10px;}
          h2 { font-family: 'Montserrat', system-ui, -apple-system; font-weight: 600; font-size: 30px; color: #004fac; }
      }
      .step2-drop-image {
          // & {}
          img { width: 150px; height: 150px; }
      }
      .step2-drop-text {
          & { font-size: 18px; font-family: 'Mulish'; padding: 0 5px; }
          .browse {
              & { z-index: 11; position: relative; text-decoration: underline; color: #004fac;  }
              .get-img { cursor: pointer; position: absolute; top: 0; left: 0; right: 0; bottom: 0; opacity: 0; width: 65px; }
          }
      }
      .d-n-d {
        & { opacity: 0; position: absolute; height: 100%; width: 100%; }
        .dropzone {
          & { height: 100%; width: 100%; }
        }
      }
    }
}
// step2a

.step2a-container {
    & { height: 500px; margin-top: 100px; display: flex; align-items: center; justify-content: center;  }

    .step2a {
        & { width: 70%; height: 100%; text-align: center; background: #f5f7fb; border: 3px dashed #004fac; border-radius: 20px;  display: flex; flex-direction: column; align-items: center; justify-content: space-evenly; }
        .step2a-loader {
            & { display: flex; flex-direction: column; justify-content: space-evenly; align-items: center;} 
            img { height: 100px; width: 100px; }
            .loading { margin-top: 10px; color: #004fac; font-size: 20px; font-family: 'Mulish'; font-weight: 600; }
        }
    }
}

// step2b

.step2b-container {
    & { height: 500px; margin-top: 100px; display: flex; align-items: center; justify-content: center; }

    .step2b {
        & { width: 70%; height: 100%; text-align: center;  background: #f5f7fb; border: 3px dashed #004fac; border-radius: 20px; display: flex; flex-direction: column; align-items: center; justify-content: space-evenly; }
        .step2b-heading {
            & { padding: 0 10px; }
            h2 { font-family: 'Montserrat', system-ui, -apple-system; font-weight: 600; font-size: 30px; color: #ff0000; }
            span {font-size: 18px; color: #ff0000;}
        }
        .step2b-drop-image {
            & {background: #ff000030; border-radius: 100%; height: 150px; width: 150px; display: flex; align-items: center; justify-content: center;}
            .MuiSvgIcon-root {height: 50px; width: 50px; color: #ff0000;}
        }
        .step2b-drop-text {
            & { font-size: 18px; font-family: 'Mulish'; padding: 0 5px; }
            .browse {
                & { position: relative; text-decoration: underline; color: #004fac;  }
                .get-img { cursor: pointer; position: absolute; top: 0; left: 0; right: 0; bottom: 0; opacity: 0; width: 65px; }
            }
        }
      .d-n-d {
        & { opacity: 0; position: absolute; height: 100%; width: 100%; }
        .dropzone {
          & { height: 100%; width: 100%; }
        }
      }
    }
}


// step2c

.step2c-container {
    & { height: 500px; margin-top: 100px; display: flex; align-items: center; justify-content: center; }

    .step2c
     {
        & { width: 70%; height: 100%; text-align: center; background: #f5f7fb; border: 3px dashed #004fac; border-radius: 20px; display: flex; flex-direction: column; align-items: center; justify-content: space-evenly; }
        .step2c-heading {
            & { padding: 0 10px; }
            h2 { font-family: 'Montserrat', system-ui, -apple-system; font-weight: 600; font-size: 30px; color: #1c2238; }
            span { font-size: 18px; color: #004fac;}
        }
        .step2c-drop-image {
            & { background: #004fac30; border-radius: 100%; height: 150px; width: 150px; display: flex; align-items: center; justify-content: center;}
            .MuiSvgIcon-root { height: 50px; width: 50px; color: #004fac; }
        }
        .step2c-drop-text {
            & { font-size: 18px; font-family: 'Mulish'; color: #004fac; }
            .file-name { display: flex; align-items: center; justify-content: space-between; padding: 10px 20px; background: #fff; color: #004fac; }
            .MuiSvgIcon-root { cursor: pointer; height: 20px; width: 20px; margin-left: 10px;}
        }
       
    }
}

// step4

.step4-container {
    & { text-align: center; height: 80vh; margin-top: 50px; display: flex; flex-direction: column; align-items: center; justify-content: space-evenly;}

   .step4-header {
    & {margin-bottom: 45px;}
    h1 { font-family: 'Montserrat'; font-weight: 500; }
    h3 { font-family: 'Mulish'; font-family: 300;}
   }
   .step4-slider { & { width: 600px; }
    .MuiSlider-root { color: #2250f3; }
    .MuiSlider-valueLabel { background: #2250f3; }
    .MuiSlider-rail { background: #aaa; height: 7px; }
    .MuiSlider-track { height: 7px; }
    .MuiSlider-mark { display: none; }
    .MuiSlider-markLabel { color: #2250f3; font-weight: bold; }
    .MuiSlider-thumb { border: 1px solid #bbb; height: 15px; width: 15px; background: #fff;}

    }
   .step4-radio-select { & { width: 500px; margin-bottom: 45px; }
    .Mui-checked { color: #2250f3; }
    .MuiRadio-root {margin-left: 15px;}
    svg { height: 15px; width: 15px; }

    }
    .step4-coupon-desc {
        & {display: flex; flex-direction: column; align-items: center; justify-content: space-evenly;}
        h1 { font-family: 'Montserrat'; font-weight: 500; }
        textarea { background: #f5f7fb; font-family: 'Mulish'; outline: #eee; border: 2px solid #ddd; border-radius: 5px; padding: 5px;}
        blockquote { font-family: 'Mulish'; margin: 0; align-self: end; color: #6f7f9f; text-transform: uppercase; font-size: 12px;}
    }

    .slider-duration-type {
      & { position: absolute; bottom: -75px; color: #1c2238; font-size: 12px; font-weight: normal; left: 50%; transform: translate(-50%, 0); }
    }
}

// step 5

.step5-container {
    & { margin: 50px 0; margin-top: 100px; display: flex; align-items: center; justify-content: space-evenly; }

    .step5-part1 {
        & { text-align: center; position: relative; }
        .heading { 
          & { margin-bottom: 50px; }
          h1 {font-family: 'Montserrat'; font-weight: 600; margin-bottom: 0;}
          h3 {font-family: 'Mulish'; font-weight: 400; margin-top: 0;}
        }
        .image {
            img { height: 150px; width: 150px; }
        }
        .image {
          & { display: flex; align-items: center; justify-content: center; }
        }
        .input {
            & { margin-top: 50px; display: flex; flex-direction: column; align-items: center;}
            label { margin-bottom: 10px; color: #6f7f9f; font-family: 'Mulish'; font-weight: 600;}
            input { text-align: center; width: 300px; }
            .helper-text { position: absolute; bottom: -38px;  font-size: 12px; color: #6f7f9f; font-family: 'Mulish'; font-weight: 600; }
        }
    }

    .step5-part2 {
        & {text-align: center; position: relative;}
        .heading { 
          & { margin-bottom: 50px; }
          h1 {font-family: 'Montserrat'; font-weight: 600; margin-bottom: 0;}
          h3 {font-family: 'Mulish'; font-weight: 400; margin-top: 0;}
        }
        .image {
          img { height: 150px; width: 150px; }
        }
        .image {
          & { display: flex; align-items: center; justify-content: center; }
        }
        .input {
            & {  margin-top: 50px; display: flex; flex-direction: column; align-items: center;}
            label { margin-bottom: 10px; color: #6f7f9f; font-family: 'Mulish'; font-weight: 600;}
            input { text-align: center; width: 300px; }
            .helper-text { position: absolute; bottom: -22px; font-size: 12px; color: #6f7f9f; font-family: 'Mulish'; font-weight: 600; }
        }
    }
}

// step 6

.step6-container {
    & { height: 80vh; max-width: 1000px; margin: 0 auto; display: flex; align-items: center; justify-content: space-evenly; margin-top: 70px; }

    .step6 {
        & { text-align: center; width: 100%; display: flex; flex-direction: column; align-items: center; justify-content: space-evenly; }
        .heading { & { margin-bottom: 50px; }
        h1 {font-family: 'Montserrat'; font-weight: 600; margin-bottom: 0;}
        h3 {font-family: 'Mulish'; font-weight: 500; margin-top: 0;}
    }
    }
    .select-terms {
        & {padding: 20px; background: #f5f7fb; border-radius: 5px; max-width: 100%; min-width: 85%; max-height: 400px; overflow-y: scroll; display: flex; flex-direction: column; flex-wrap: nowrap; align-items: center; }
        .term {
            & { width: 95%; padding: 5px 10px; margin-bottom: 5px; background: #fff; border-radius: 5px; display: flex; align-items: center; justify-content: space-between; }
            .close { cursor: pointer; height: 18px; width: 18px; color: #aaa; }
            .MuiCheckbox-root { width: 50px; min-width: 50px; }
            .MuiCheckbox-root.Mui-checked, .MuiCheckbox-indeterminate { color: #2b1de8; }
            .MuiTypography-root {font-family: 'Mulish'; font-weight: 300;}
        }
    }
}

.create-new {
    & { display: flex; align-items: center; color: #2b1de8; margin-top: 20px; cursor: pointer; }
    span { margin-left: 5px; font-family: 'Mulish'; font-weight: 600; }
}

// step7

.step7-container {
    & { display: flex; align-items: center; justify-content: center; border-radius: 20px; margin: 4rem 0; }

    .step7 {
        & { width: 100%; height: 100%; text-align: center; width: 1000px; display: flex; flex-direction: column; align-items: center; justify-content: center; }
        .step7-heading {
            & {margin-bottom: 50px;}
            h1 { font-family: 'Montserrat', system-ui, -apple-system; font-weight: 600; font-size: 30px; color: #1c2238; }
            h3 { font-family: 'Mulish', system-ui, -apple-system; font-weight: 400; font-size: 18px; color: #1c2238; }
        }
        .step7-drop-image {
            & { margin-bottom: 50px; background: #004fac30; border-radius: 100%; height: 150px; width: 150px; display: flex; align-items: center; justify-content: center;}
            img { height: 150px; width: 150px; }
            .MuiSvgIcon-root { height: 50px; width: 50px; color: #004fac; }
        }
        .select-terms {
          & {padding: 20px; background: #f5f7fb; border-radius: 5px; max-width: 100%; min-width: 85%; max-height: 400px; overflow-y: scroll; display: flex; flex-direction: column; flex-wrap: nowrap; align-items: center; }
          .term {
              & { width: 95%; padding: 5px 10px; margin-bottom: 5px; background: #fff; border-radius: 5px; display: flex; align-items: center; justify-content: space-between; }
              .close { cursor: pointer; height: 18px; width: 18px; color: #aaa; }
              .MuiCheckbox-root.Mui-checked, .MuiCheckbox-indeterminate { color: #2b1de8; }
              .MuiTypography-root {font-family: 'Mulish'; font-weight: 300;}
          }
      }
      .locations-table {
        & { background: #f5f7fb; height: 350px; width: 100%; }
        .table-head {
          & { padding: .5rem 1rem; height: 40px; width: 100%; background: #f8fafd; color:#6f7f9f; display: flex; align-items: center; }
          .title {
            & { margin-right: 200px; }
          }
        }
        .table-body {
          & { height: calc(100% - 40px); width: 100%; padding: 1rem; overflow-y: scroll; }
          .location-item {
            & { display: flex; align-items: center; justify-content: flex-start; width: 100%; background: #ffffff; padding: 10px; margin-bottom: 10px; border-radius: 6px; }
            .address-title {
              & { text-align: left; width: 25%; }
            }
            .address-address {
              & { text-align: left; width: calc(75% - 18px); }
            }
            .close {
              & { cursor: pointer; color:#6f7f9f; height: 18px; width: 18px; }
            }
          }
        }
      }
      .map-h2 {
        text-transform: uppercase;
        font-size: 1rem;
        padding: 20px;
        padding-left: 10px;
        text-align: center;
      }
      
      .google-map {
        width: 100%;
        height: 60vh;
      }
      
      
    }
    
  }
  .pin {
    & { display: flex; align-items: center; width: 180px; color: #ffffff; }

    .pin-icon {
      font-size: 2rem;
    }
    
    .pin-text {
      font-size: 10px;
    }
    
  }

// step 7a

.step7a-container {
    & { height: 80vh; max-width: 1000px; margin: 0 auto; display: flex; align-items: center; justify-content: space-evenly; margin-top: 70px; }

    .step7a {
        & { text-align: center; width: 100%; display: flex; flex-direction: column; align-items: center; justify-content: space-evenly; }
        .heading { 
          & {  }
          h1 {font-family: 'Montserrat'; font-weight: 600; margin-bottom: 0;}
          h3 {font-family: 'Mulish'; font-weight: 500; margin-top: 0;}
        }
    }
    .select-terms {
        & { position: relative; padding: 20px; padding-top: 0; background: #f5f7fb; border-radius: 5px; width: 100%;  max-height: 400px;  overflow-y: scroll; display: flex; flex-direction: column; flex-wrap: nowrap; align-items: center; }
        .term-heading {
            & { position: sticky; top: 0; left: 0; right: 0; width: 100%; padding: 5px 10px; margin-bottom: 5px; color: #6f7f9f; font-weight: 600; background: #f8fafd; border-radius: 5px; display: flex; align-items: center; justify-content: space-around; }
            .title {margin-right: auto;}
            .location {margin-right: auto;}
        }
        .term {
            & { width: 100%; padding: 10px 10px; margin-bottom: 7px; background: #fff; border-radius: 5px; display: flex; align-items: center; justify-content: space-between; }
            .close { cursor: pointer; height: 18px; width: 18px; color: #aaa; }
            .MuiCheckbox-root.Mui-checked, .MuiCheckbox-indeterminate { color: #2b1de8; }
            .MuiTypography-root {font-family: 'Mulish'; font-weight: 300;}
        }
    }
}

// step 8

.step8-container {
    & { max-width: 1000px; margin: 4rem auto; display: flex; align-items: center; justify-content: space-evenly; }

    .step8 {
      & { margin-bottom: 4rem; text-align: center; max-width: 100%; min-width: 100%; display: flex; flex-direction: column; align-items: center; justify-content: space-evenly; }
      .heading { & { margin-bottom: 50px; }
      h1 {font-family: 'Montserrat'; font-weight: 600; margin-bottom: 0;}
    }
    }
    .selected-categories {
        & { z-index: 100; width: 100%; padding: 5px 0px; margin-bottom: 5px; color: #6f7f9f; font-weight: 600; background: #ffffff; border-radius: 5px; display: flex; align-items: center; flex-wrap: wrap; }
        .categories { 
            & { display: flex; align-items: center; margin: 5px 10px; padding: 5px 10px; border-radius: 5px; background: #f5f7fb; color: #2b1de8; }
            .MuiSvgIcon-root { cursor: pointer; height: 15px; width: 15px; margin-left: 10px; bottom: -10px; }
        }
    }
    .select-categories {
        & { position: relative; padding-top: 0; padding: 15px; background: #f5f7fb; border-radius: 5px; width: 100%; max-height: 500px; min-height: 500px; overflow-y: scroll; display: flex; flex-direction: column; flex-wrap: nowrap; align-items: center; }
        .MuiPaper-root {width: 90%; margin: 10px 0; }
        .MuiAccordion-root:before { opacity: 0; }
        .MuiAccordion-root { box-shadow: none; }
        .MuiAccordion-root { box-shadow: none; }
        .MuiAccordionSummary-content {
            span {font-family: 'Mulish'; font-weight: 700;}
        }
        .MuiAccordionDetails-root {
            div { display: flex; flex-direction: column; }
        }
        .MuiSvgIcon-root { color: #2b1de8; }
        .MuiTypography-root { color: #24232c; font-family: 'Mulish';}
    }
}

.create-new {
    & { display: flex; align-items: center; color: #2b1de8; margin-top: 20px; cursor: pointer; }
    span { margin-left: 5px; font-family: 'Mulish'; font-weight: 600; }
}

// resume page

.coupon-details-container-1 {
    & { background: #c22130; margin-top: 70px; display: flex; flex-direction: column; align-items: center; }

   
    .full-width { max-width: 1000px; width: 90%; text-align: center; }

    .resume-logo { 
        position: relative; padding-top: 50px;
        img { height: 200px; width: 200px; }
    }
    .resume-company-name {
        & { position: relative; }
        h1 { color: #fff; font-family: 'Montserrat'; font-size: 45px; font-weight: normal; }
    }
    .resume-mm-details {
        & { padding-bottom: 50px; display: flex; align-items: center; flex-direction: column; max-width: 1000px; width: 90%; }
        .mm-details {
            & { width: 100%; color: #fff;  margin: 20px 0; display: flex; align-items: center; justify-content: space-between;}
            .block-1 { width: 50%; padding-left: 50px; display: flex; align-items: center; flex-direction: column; }
            .block-2 { width: 50%; padding-left: 50px; display: flex; align-items: center; flex-direction: column; }

            .bold-title { padding: 5px 0; font-family: 'Mulish'; font-weight: 700; text-transform: uppercase; width: 75%; text-align: start; }
            .light-title { font-family: 'Mulish'; font-weight: 200; font-size: 18px; width: 75%; text-align: start; }
        }
    }
}

.coupon-coupon-container-1 {
    & { background: #f5f7fb; display: flex; align-items: center; justify-content: center; height: 300px; width: 100%;}
    
    .coupon-card-container {
                    & { position: relative; max-width: 1000px; width: 90%; display: flex; align-items: center; justify-content: center; }
        .coupon {
            & { box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px; color: #fff; background: #c22130; border-radius: 10px; width: 375px; height: 150px; display: flex; align-items: center; justify-content: space-around; }
            
            .MuiDivider-root { width: 2%; border-color: #fff !important; border-style: dashed; height: 85%; border-right-width: 2px; }
    
            .coupon-left {
                & { width: 150px; display: flex; flex-direction: column; align-items: center;}
                h1 { margin-top: 15px; margin-bottom: 5px; font-family: 'Roboto'; font-size: 40px; }
                span {font-size: 18px; font-family: 'Mulish';}
                h2:last-child { margin-top: 10px; margin-bottom: 10px; font-family: 'Mulish'; font-weight: 900; font-size: 22px;}
            } 
            
            .coupon-right {
                & { height: 150px; width: 150px; }
            }
        }
    }
}

// first coupon container
.coupon-details-container-2 {
    & { background: #fff; color: #000; margin-top: 100px; display: flex; flex-direction: column; align-items: center; }

    .full-width { max-width: 1000px; width: 90%; text-align: center; }

    .resume-logo { 
      & { position: relative; padding-top: 70px; }
        
        img { height: 200px; width: 200px; margin: 0 auto; }
    }
    .resume-company-name {
        & { position: relative; }
        h1 { font-family: 'Montserrat'; font-size: 45px; font-weight: normal; }
    }
    .resume-mm-details {
        & { padding: 50px 0; margin-top: 1rem; display: flex; align-items: center; flex-direction: column; max-width: 1000px; width: 90%; }
        .mm-details {
            & { width: 100%;  margin: 20px 0; display: flex; align-items: center; justify-content: space-between;}
            .block-1 { width: 50%; padding-left: 50px; display: flex; align-items: center; flex-direction: column; }
            .block-2 { width: 50%; padding-left: 50px; display: flex; align-items: center; flex-direction: column; }

            .bold-title { padding: 5px 0; font-family: 'Mulish'; font-weight: 700; text-transform: uppercase; width: 75%; text-align: start; }
            .light-title { font-family: 'Mulish'; font-weight: 200; font-size: 18px; width: 75%; text-align: start; }
        }
    }
}

// edit button for resume
    
.edit { cursor: pointer; position: absolute; right: 10px; top: 5%; bottom: 50%; z-index: 100; }
.red-bg { color: #fff; background: transparent; border: 1px solid #fff; border-radius: 5px; }
.gray-bg { color: #2b1de8; background: #f5f7fb; border-radius: 5px; }
.white-bg { color: #2b1de8; background: white; border-radius: 5px; }


// second coupon container
.coupon-coupon-container-2 {
   
    & { background: #f5f7fb; display: flex; flex-direction: column; gap: 35px; align-items: center; justify-content: center; height: 650px; width: 100%;}
   .coupon-card-container {
            & { position: relative; max-width: 1000px; width: 90%; display: flex; align-items: center; justify-content: center; }
       .coupon {
           & { padding: 1rem 0; position: relative; box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px; color: #000; background: #fff; border-radius: 10px; width: 375px; height: 150px; display: flex; align-items: center; justify-content: space-around; }
           
           .MuiDivider-root { width: 2%; border-color: #000 !important; border-style: dashed; height: 85%; border-right-width: 2px; }
   
           .coupon-left {
               & { width: 150px; display: flex; flex-direction: column; align-items: center;}
               h1 { margin-top: 15px; margin-bottom: 5px; font-family: 'Roboto'; font-size: 40px; }
               span { font-size: 18px; font-family: 'Mulish'; color: #686868; text-align: center;}
               h2:last-child { margin-top: 10px; margin-bottom: 10px; font-family: 'Mulish'; font-weight: 900; font-size: 22px;}
           } 
           
           .coupon-right {
                & { height: 100%; width: 150px; }
           }
       }
       .coupon-desc {
   
           & { position: relative; box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px; color: #000; background: #fff; border-radius: 10px; width: 375px; height: 150px; display: flex; flex-direction: column; align-items: center; justify-content: center; }
               h1 { margin-top: 15px; margin-bottom: 5px; font-family: 'Montserrat'; font-size: 30px; font-weight: normal; }
               h3 { margin-top: 10px; margin-bottom: 10px; font-size: 18px; font-family: 'Mulish'; color: #000; font-weight: normal; text-align: center;}
           
       }
       .coupon-details {
           & { position: relative; background-image: url("./assets/img/ad/circle-lights-2.svg"),  linear-gradient(to bottom, #2b1de8, #0094ff); background-repeat: no-repeat; background-size: cover; box-shadow: rgb(0 148 255 / 58%) 0px 28px 33px -20px; color: #fff; border-radius: 10px; width: 375px; height: 150px; display: flex; align-items: center; justify-content: space-around; }
           
           .MuiDivider-root { width: 2%; border-color: #fff !important; border-style: dashed; height: 85%; border-right-width: 2px; }
   
           .coupon-left {
               & { width: 150px; display: flex; flex-direction: column; align-items: center;}
               h1 { margin-top: 0px; margin-bottom: 0px; font-family: 'Roboto'; font-size: 65px; }
               h3 { margin-top: -15px; margin-bottom: 0px; font-family: 'Montserrat'; font-weight: 400; font-size: 22px;}
               span {font-size: 12px; font-family: 'Montserrat'; text-transform: uppercase;}
           } 
           
           .coupon-right {
               & { width: 150px; display: flex; flex-direction: column; align-items: center;}
               h1 { margin-top: 0px; margin-bottom: 0px; font-family: 'Roboto'; font-size: 45px; }
               h3 { margin-top: -10px; margin-bottom: 0px; font-family: 'Montserrat'; font-weight: 400; font-size: 20px;}
           }
       }
   }
}

// resume terms and condition

.coupon-terms-container {
    & { background: #fff; color: #000; margin-top: 50px; padding-top: 2rem; display: flex; flex-direction: column; align-items: center; }

   
    .full-width { max-width: 1000px; width: 90%; text-align: center; }

    .main-title {
        & { position: relative; }
        h2 { color: #000; font-family: 'Montserrat'; font-size: 35px; font-weight: 400; }
    }
    .resume-terms {
        & { padding-bottom: 50px; display: flex; align-items: center; flex-direction: column; max-width: 1000px; width: 90%; }
        .terms {
            & { width: 100%; color: #000;  margin: 20px 0; display: flex; flex-wrap: wrap; align-items: center; justify-content: space-between;}
            .term { width: 49%; padding:15px 0 10px 50px; display: flex; align-items: flex-start; }

            .term-number { color: #2b1de8; font-size: 17px; font-family: 'Roboto'; font-weight: 700; text-transform: uppercase; width: 30px; text-align: start; }
            .term-text { font-family: 'Mulish'; font-weight: 400; font-size: 18px; width: calc(100% - 30px); text-align: start; }
        }
    }
}


// coupon website and coupon 

.coupon-website-code-container {
   
    & { background: #f5f7fb; display: flex; flex-direction: column; gap: 40px; align-items: center; justify-content: center; height: 650px; width: 100%;}
   .coupon-website-container {
            & { position: relative; max-width: 1000px; width: 90%; display: flex; align-items: center; justify-content: center; }
 
       .coupon-website {
   
           & { position: relative; box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px; color: #000; background: #fff; border-radius: 10px; width: 80%; height: 150px; display: flex; flex-direction: column; align-items: center; justify-content: center; }
               h1 { margin-top: 15px; margin-bottom: 5px; font-family: 'Montserrat'; font-size: 30px; font-weight: normal; }
               h3 { margin-top: 10px; margin-bottom: 10px; font-size: 18px; font-family: 'Mulish'; color: #2b1de8; font-weight: 600; text-align: center;}
           
       }
   }

   .coupon-code-container {
            & { position: relative; max-width: 1000px; width: 90%; display: flex; align-items: center; justify-content: center; }
 
       .coupon-code {
   
           & { position: relative; box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px; color: #000; background: #fff; border-radius: 10px; width: 80%; height: 150px; display: flex; flex-direction: column; align-items: center; justify-content: center; }
           h2 { margin-top: 5px; margin-bottom: 5px; font-family: 'Montserrat'; font-size: 30px; font-weight: normal; }
           h1 { margin-top: 5px; margin-bottom: 5px; font-size: 45px; font-family: 'Roboto'; color: #2b1de8; font-weight: 900; text-align: center;}
           
       }
   }
}

// locations

.coupon-location-container {
    & { background: #fff; color: #000; margin-top: 50px; display: flex; flex-direction: column; align-items: center; }

   
    .full-width { max-width: 1000px; width: 90%; text-align: center; }

    .main-title {
        & { position: relative; }
        h2 { color: #000; font-family: 'Montserrat'; font-size: 35px; font-weight: 400; }
    }
    .resume-location {
        & { padding-bottom: 50px; display: flex; align-items: center; flex-direction: column; max-width: 1000px; width: 90%; }
        .locations {
            & { width: 100%; color: #000;  margin: 20px 0; display: flex; flex-wrap: wrap; align-items: center; justify-content: space-between;}
            .location { width: 50%; display: flex; align-items: flex-start; }

            .location-number { padding-top: 2px; color: #686868; font-size: 17px; font-family: 'Roboto'; font-weight: 700; text-transform: uppercase; width: 10%; text-align: start; }
            .location-text { 
                & { font-family: 'Mulish'; font-weight: 400; font-size: 18px; width: 75%; text-align: start; display: flex; align-items: flex-start; justify-content: flex-start; flex-direction: column; }
                .location-title { color: #2b1de8; font-family: 'Montserrat'; font-weight: 600; margin-bottom: 15px;}
                .location-address { font-family: 'Mulish';}
            }
        }
    }
}


// Categories

.coupon-categories-container {
   
    & { background: #f5f7fb; display: flex; flex-direction: column; gap: 40px; align-items: center; justify-content: center; width: 100%; padding: 50px 0;}
   .coupon-categorie {
            & { position: relative; max-width: 1000px; width: 90%; display: flex; align-items: center; justify-content: center; margin: 50px 0; }
 
       .coupon-categories {
            & { padding: 45px 0; position: relative; box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px; color: #000; background: #fff; border-radius: 10px; width: 80%; display: flex; flex-direction: column; align-items: center; justify-content: center; }
            .title { margin-top: 15px; margin-bottom: 5px; font-family: 'Montserrat'; font-size: 30px; font-weight: normal; }
            .categories { 
                & { display: flex; flex-wrap: wrap; align-items: center; justify-content: center; width: 85%; }
                .category { padding: 10px 10px; border-radius: 5px; margin: 10px 10px ; }
            }
       }
   }

}
// Pay

.pay-container {
   
    & { background: #fff; display: flex; align-items: center; justify-content: center; width: 100%; padding: 65px 0;}
   .pay {
            & { max-width: 1000px; width: 90%; display: flex; align-items: center; justify-content: center; }
        .pay-button { 
            & { cursor: pointer; font-family: 'Mulish'; font-weight: 600; padding: 8px; background-image: url("./assets/img/ad/circle_lights.svg"),  linear-gradient(to bottom, #2b1de8, #0094ff); background-repeat: no-repeat; background-size: cover; box-shadow: rgb(0 148 255 / 58%) 0px 28px 33px -20px; color: #fff; border-radius: 5px; width: 100px; text-align: center; }
         }
   }

}



/* width */
::-webkit-scrollbar {
   width: 7px;
   height: 7px;
}

/* Track */
::-webkit-scrollbar-track {
   background: #f1f1f1; 
}

/* Handle */
::-webkit-scrollbar-thumb {
   background: #888; 
   border-radius:10px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
   background: #555; 
}
@media (max-width: 1200px) {
    .price-plan-container .price-plan {flex-wrap: wrap; gap: 35px; margin-bottom: 30px;}
}

@media (max-width: 768px) {

    .blue-btn .MuiButtonBase-root { margin: 0; }

    .price-plan-container .price-plan {flex-direction: column; gap: 35px; margin-bottom: 30px;}

    // yes or no
    .yes-no-container .yes-no-right { display: none; }
    .yes-no-container .yes-no-left { width: 100%; }

    // Help me
    .help-container .help-me .help-heading h1 { font-size: 24px;}
    .help-container .help-me .help-heading h2 { font-size: 20px;}
    
    .help-container .help-me .help-input .input-first { flex-direction: column; margin: 0; }
    .help-container .help-me .help-input .input-first input { width: 300px; }
    .help-container .help-me .help-input .input-first .MuiInputBase-root { margin: 25px 0; }
    
    .help-container .help-me .help-input .input-second { flex-direction: column; margin-top: 0; }
    .help-container .help-me .help-input .input-second input { width: 300px; }
    .help-container .help-me .help-input .input-second .MuiInputBase-root { margin: 25px 0; }
    
    .help-container .help-me .help-input textarea { width: 85%; }
    
    // step 1
    .step1-container .step1 .step1-heading { margin: 0; }
    .step1-container .step1 .step1-heading h1 { margin: 0; font-size: 24px;}
    
    .step1-container .step1 .step1-input .input-first { flex-direction: column; margin: 0; }
    .step1-container .step1 .step1-input .input-first input { width: 300px; }
    
    .step1-container .step1 .step1-input .input-second { flex-direction: column; margin: 0; }
    .step1-container .step1 .step1-input .input-second input { width: 300px; }

    .step1-container .step1 .step1-input .input-third { flex-direction: column; margin-top: 0; }
    .step1-container .step1 .step1-input .input-third .add-margin { margin-top: 20px; }
    .step1-container .step1 .step1-input .input-third input { width: 300px; }
    
    // step 2
    .step2-container .step2 { width: 100%; }
    .step2a-container .step2a { width: 100%; }
    .step2b-container .step2b { width: 100%; }
    .step2c-container .step2c { width: 100%; }

    // step 3
    .coupon-select-container .coupon-container { flex-direction: column; margin-bottom: 150px; }

    // step 4
    .step4-container .step4-header { margin: 0; width: 80%; }
    .step4-container .step4-slider { width: 300px; }
    .step4-container .step4-radio-select { width: 330px; margin-bottom: 0px; }
    .step4-container .step4-radio-select .MuiRadio-root { margin-left: -5px; }
    .step4-container .step4-radio-select .MuiFormControlLabel-root { margin: 0 5px; }
    .step4-container .step4-coupon-desc h1 { font-size: 20px; margin: 10px 0; }
    .step4-container .step4-coupon-desc textarea { width: 300px; margin: 0px; }

    // step 5
    .step5-container { flex-direction: column; }
    // .step5-container .step5-part1 { padding-top: 250px; }
    .step5-container .step5-part2 { padding-bottom: 70px; }
    // step 6

    .step6-container .step6 .heading { padding: 0 15px; }
    .step6-container .step6 .heading h1 { font-size: 30px; }
    .step6-container .select-terms { padding: 0 10px; }

    // logo and back link

    .deliva-logo { padding: 0 20px; height: 65px; }
    .logo { height: 50px; width: 50px; }

    // remume page

    .coupon-details-container-1 { margin-top: 70px; }
    .coupon-details-container-2 { margin: 0; }

    .coupon-details-container-1 .resume-company-name h1 { font-size: 32px; text-align: center;}
    .coupon-details-container-1 .resume-mm-details .mm-details { margin: 0; flex-direction: column; }
    .coupon-details-container-1 .resume-mm-details .mm-details .block-1 { padding: 0; margin: 10px 0; width: 100%; }
    .coupon-details-container-1 .resume-mm-details .mm-details .block-2 { padding: 0; margin: 10px 0; width: 100%; }
    .coupon-details-container-1 .resume-mm-details .mm-details .bold-title { text-align: center; }
    .coupon-details-container-1 .resume-mm-details .mm-details .light-title { text-align: center;}

    .coupon-details-container-2 .resume-company-name h1 { font-size: 32px; text-align: center;}
    .coupon-details-container-2 .resume-mm-details .mm-details { margin: 0; flex-direction: column; }
    .coupon-details-container-2 .resume-mm-details .mm-details .block-1 { padding: 0; margin: 10px 0; width: 100%; }
    .coupon-details-container-2 .resume-mm-details .mm-details .block-2 { padding: 0; margin: 10px 0; width: 100%; }
    .coupon-details-container-2 .resume-mm-details .mm-details .bold-title { text-align: center; }
    .coupon-details-container-2 .resume-mm-details .mm-details .light-title { text-align: center;}

    .coupon-terms-container .main-title { height: 65px; margin-bottom: 15px; }
    .coupon-terms-container .main-title h2 { font-size: 32px; margin-bottom: 10px; }
    .coupon-terms-container .resume-terms .terms { flex-direction: column; }
    .coupon-terms-container .resume-terms .terms .term { width: 100%; padding: 5px 0; justify-content: center; }
    
    .coupon-website-code-container { height: 475px; }
    .coupon-website-code-container .coupon-website-container .coupon-website { width: 100%; }
    .coupon-website-code-container .coupon-website-container .coupon-website h3 {padding: 0 15px; word-break: break-all; font-size: 16px; }
    .coupon-website-code-container .coupon-code-container .coupon-code { width: 100%; }
    .coupon-website-code-container .coupon-code-container .coupon-code h1 { font-size: 36px; }

    .coupon-location-container .main-title h2 { font-size: 32px; }
    .coupon-location-container .resume-location .locations { flex-direction: column; }
    .coupon-location-container .resume-location .locations .location-text .location-title { margin-bottom: 5px;}
    .coupon-location-container .resume-location .locations .location { margin-bottom: 15px; width: 100%; padding: 0; justify-content: center; }

    .coupon-categories-container .coupon-categorie .coupon-categories { width: 100%; }
    .coupon-categories-container .coupon-categorie .coupon-categories .categories { width: 100%; }

    .coupon-categories-container { padding: 0; }

    // add new term modal
    .css-1o65sjb { width: 90%; }
    .new-term-container .new-term .btn-close { top: -10px; }
    .css-1tcs2i1-MuiInputBase-root-MuiInput-root { width: 100%; }
    .new-term-container { margin: 0; }
    .new-term-container .new-term { width: 100%; }
    .new-term-container .new-term .image { width: 40%; margin: 0 auto; }
    .new-term-container .new-term .heading h1 { font-size: 24px; margin-bottom: 25px; margin-top: 1rem; }
    .new-term-container .new-term .input { margin: 25px auto; }

    //button
    .blue-border-btn .MuiButtonBase-root { margin: 0; }
    .yes-no-container .yes-no-left .yes-no .btn-wrapper { justify-content: space-evenly; }

    .new-location-container .address-title-input { flex-direction: column; }
    .new-location-container .address-title-input .input { width: 100%; }
    .new-location-container .address-title-input .input input { width: 100%; }
    .new-location-container .address-title-input .input label { display: none; }
    .new-location-container .btn-close { top: 10px; }

    .step5-container { margin-top: 50px; }
    .step5-part1 { margin-bottom: 2rem; }

 }

@media (max-width: 600px) {
    
    // stepper
    .stepper .stepper-control .MuiButtonBase-root { width: 110px; }
    .stepper .stepper-control .next-step h3 { font-size: 14px; }
    
    // back btn
    .back-link { left: 15px; }
    
    .price-plan-container .price-plan {flex-direction: column; gap: 35px; margin-bottom: 75px;}
    .yes-no-container .yes-no-right { display: none; }
    .yes-no-container .yes-no-left { width: 100%; }
    
    .help-container .help-me { display: flex; flex-direction: column; align-items: center; } 
    .help-container .help-me .help-heading { width: 70%; margin: 0; margin-top: 25px; display: flex; flex-direction: column; align-items: center; justify-content: center; } 
    
    // part 5
    .step5-container .step5-part1 { padding-top: 30px; }

    // new term
    .css-1o65sjb {width: 95% !important; padding: 1rem !important; }
    .new-term-container .new-term .image { width: 100%; margin: 0 auto; }
    
    // remume page
    .coupon-details-container-1 .resume-mm-details .mm-details .bold-title { width: 100%; text-align: center; }
    .coupon-details-container-1 .resume-mm-details .mm-details .light-title { width: 100%; text-align: center;}

    .coupon-details-container-2 .resume-mm-details .mm-details .bold-title { width: 100%; text-align: center; }
    .coupon-details-container-2 .resume-mm-details .mm-details .light-title { width: 100%; text-align: center;}


    .coupon-details-container-1 .resume-company-name h1 { font-size: 28px; }
    .coupon-details-container-1 .resume-mm-details .mm-details .bold-title { font-size: 16px; }
    .coupon-details-container-1 .resume-mm-details .mm-details .light-title { font-size: 16px; }
    .coupon-details-container-2 .resume-company-name h1 { font-size: 28px; }
    .coupon-coupon-container-2 .coupon-card-container .coupon-desc h1 { font-size: 28px; }
    .coupon-coupon-container-2 .coupon-card-container .coupon-desc h3 { font-size: 16px; }
    .coupon-coupon-container-2 .coupon-card-container .coupon-details .coupon-left h1 { font-size: 50px; }
    .coupon-coupon-container-2 .coupon-card-container .coupon-details .coupon-left h3 { font-size: 18px; }
    .coupon-coupon-container-2 .coupon-card-container .coupon-details .coupon-right h1 { font-size: 36px; }
    .coupon-coupon-container-2 .coupon-card-container .coupon-details .coupon-right h3 { font-size: 18px; }
    .coupon-coupon-container-1 .coupon-card-container .coupon .coupon-left h1 { margin: 0; }
    .coupon-coupon-container-2 .coupon-card-container .coupon .coupon-left h1 { margin: 0; }
    .coupon-terms-container .main-title h2 { font-size: 28px; }
    .coupon-terms-container .resume-terms .terms .term-text { font-size: 16px; }
    .coupon-website-code-container .coupon-website-container .coupon-website h1 { font-size: 28px; }
    .coupon-website-code-container .coupon-website-container .coupon-website h3 { font-size: 14px; }
    .coupon-location-container .main-title h2 { font-size: 28px; }
    .coupon-location-container .resume-location .locations .location-text { font-size: 16px; }
    .coupon-website-code-container .coupon-code-container .coupon-code h2 { font-size: 26px; }
    .coupon-website-code-container .coupon-code-container .coupon-code h1 { font-size: 28px; }
    .coupon-categories-container .coupon-categorie .coupon-categories .title { font-size: 28px; }

    //button
    .blue-border-btn .MuiButtonBase-root { margin: 0; }
    .yes-no-container .yes-no-left .yes-no .btn-wrapper { justify-content: space-evenly; }
}






























// main h1 {margin: 10px 0 10px 0;font-size: 2.5em;font-weight: 800;text-transform: uppercase;color: #3c6382;}

// Main container and their styles
// -------------------------------
main {

  // & { width: 1}

    // Main toolbar
    .pagetitle {background: transparent !important;width: 100%;}
    .mat-toolbar {background: transparent !important;}
    .mat-toolbar-row h1 {margin: 10px 0 10px 0;font-size: 1.5em;font-weight: 800;text-transform: uppercase;color: $titlecolor1;font-family: $mainfont;}
    .mat-toolbar-row button {font-weight: 800;font-family: $mainfont;text-transform: uppercase;margin-right: 5px;}

    .table-container {display: flex;flex-direction: column;min-width: 300px;background: $themecolor4;border-top: 2px solid $themecolor1;}

    // Mat Table
    // ---------
    .mat-table {font-family: $mainfont;overflow: auto;max-height: 80vh;}
    .mat-header-row {position: sticky;top: 0;background-color: #fff;z-index: 1000;}
    .mat-header-cell {font-size: 1em;color: $themecolor1;font-family: $mainfont;text-transform: uppercase;font-weight: 800;}
    .mat-menu-item {font-family: $mainfont;letter-spacing: 0;font-size: 12px;padding: 0 8px !important;height: auto !important;line-height: 36px !important;margin-bottom: 0;}

    .draft {background: #e55039;color: #fff;font-weight: 800;padding: 2px 5px;border-radius: 2px;-moz-border-radius: 2px;-webkit-border-radius: 2px;}
    .running {background: #78e08f;color: #fff;font-weight: 800;padding: 2px 5px;border-radius: 2px;-moz-border-radius: 2px;-webkit-border-radius: 2px;}
    .closed {background: none;color: #78e08f;font-weight: 800;padding: 2px 5px;border-radius: 2px;-moz-border-radius: 2px;-webkit-border-radius: 2px;}

}

.mat-column-responses {flex: 0 0 160px !important;text-align: center !important;}
.mat-column-status {flex: 0 0 160px !important;text-align: center !important;}
.mat-column-tools {flex: 0 0 80px !important;text-align: right;}



app-lightbox { display: flex; justify-content: center; position: fixed;  width: 100%; height: 100%;
  background: rgba(0,0,0,0.5);  z-index: 10000; font-family: $mainfont; left: 0px; overflow-y: scroll; }

  .openlightbox { opacity: 1 !important; }

  .lightbox {
    & { position: absolute; border-radius: 30px; text-align: center; padding: 0px; background: #FFF; max-width: 680px; z-index: 10001; min-height: 100px; top: 64px; font-family: $mainfont;  left: 50%; transform: translate(-50%, 0px); }

    h1 { color: #1F4E96; font-size: 1.5em; margin: 0px;
    padding: 20px 0px; border-top-right-radius: 30px; border-top-left-radius: 30px; }

    h2 { font-size: 1.4em;color: #1F4E96; font-weight: 800; font-family: "proxima-nova", sans-serif; }

    .lightboxfooter { justify-content: space-between !important; padding: 15px 30px; }
    .lightboxfooter button { font-family: $mainfont; background: #f1f2f6; font-weight: 600; }

    form { margin-bottom: 0px; }
    .mat-form-field { width: 100%; }
  }

  .defaultLightbox > div { padding: 30px;
    font-family: $mainfont;
    &:last-of-type { display: flex;  justify-content: flex-end; }
    > p { text-align: justify; }
  }



// Snackbar container
// ----------------
.mat-snack-bar-container {margin: 20px !important;}
.mat-snack-bar-container-failure {background-color: $bg-failure;}
.mat-snack-bar-container-success {background-color: $bg-success;}
.mat-snack-bar-container-pending {background-color: $bg-pending;color: #fff;}

// ========================= Smiler CSS ============================== //

// =================== Global CSS ===================



// theme colors
$smilercolor1: #FFEB33;

// background
$bgmain: #f6f7fa; // body

// form
$formtext: #1F4E96;
$formbg: #eff5ff;

// classes
body { background-color: #FFF; font-family: $mainfont; }
.full-frame { max-width:1440px; margin:0 70px; width: calc(100% - 140px); }
.medium-frame { max-width:991px; margin:0 70px; width: calc(100% - 140px); }
.cdk-overlay-container { z-index: 10002; }

// spinner
.matspinner {display: flex;justify-content: center;align-items: center;min-height: 50vh;}

// buttons
.yellowBtn { background-color: $smilercolor1 !important; color:#292F36 !important; font-family: $mainfont; }
.outlineBtn { background-color: none !important; color: #292F36 !important; border: 1px solid #292F36 !important; font-family: $mainfont; }
.yellowBtn.mat-button-disabled { opacity: 0.5; }
.plainBtn { background-color: #ffffff !important; color:#007C89 !important; font-family: $mainfont; }
.plainBtn.mat-button-disabled { opacity: 0.5; }
.btn-large { box-shadow: none !important; width: 100%; border-radius: 12px !important; height: 48px; font-size: 16px; font-weight: 700; }
.btn-small { box-shadow: none !important; min-width: 120px !important; border-radius: 12px !important; height: 48px; font-size: 16px; font-weight: 700; }

.smiler-button-loader {
  & { transition: 0.1s !important; }
  &.smiler-button-loading { min-width: 50px !important; height: 50px !important; border-radius: 100% !important; padding: 0px !important; }
  .submit-loader { width: 40px; }
}

// form field
.form-field {
  .field-label { color: #292F36; font-size: 16px; font-weight: 500; }
  .mat-form-field { width:100%; }
  input[type="password"], input[type="text"] { border: none !important; padding: 0px; height: auto; background-color: transparent; }
  .mat-form-field {
    & { width:100%; font-family: $mainfont; }
    // .mat-form-field-wrapper { margin: 0; padding: 0; }
    .mat-form-field-infix { padding: 12px 0 20px 0; border-top-width: 12px; }
    .mat-form-field-outline-start, .mat-form-field-outline-end { border-width: 0; }
     input::placeholder { font-size: 14px; color: #D0D5DB; font-family: $mainfont; }
     input { font-size: 14px; color: $formtext !important; font-family: $mainfont; }
     textarea { border:none;  font-size: 14px; color: $formtext; }
     textarea::placeholder { color: #D0D5DB; }
     .mat-form-field-outline { background: #F2F4FC; border-radius: 12px; }
     .mat-form-field-outline-thick .mat-form-field-outline-start,
     .mat-form-field-outline-thick .mat-form-field-outline-end,
     .mat-form-field-outline-thick .mat-form-field-outline-gap {
      border-width: 0px ;
      }
      .mat-form-field-suffix { top: 7px; }
      .mat-form-field-suffix .material-icons { color: #E2E7F4; }
      .mat-form-field-suffix .material-icons.valid { color: #718194; }
      .mat-error { font-weight: 600; }
   }
}

// checkbox
.circle-checkbox {
  .mat-checkbox-frame { border-color: #C7C9D9; border-radius: 100%; border-width: 1px; background-color: #F2F2F5 ; }
  &.mat-checkbox-indeterminate.mat-accent .mat-checkbox-background, &.mat-checkbox-checked.mat-accent .mat-checkbox-background { background-color: #FFFD00; border-radius: 100%; }
  &.mat-checkbox-checked:not(.mat-checkbox-disabled).mat-accent .mat-ripple-element, &.mat-checkbox:active:not(.mat-checkbox-disabled).mat-accent .mat-ripple-element  { background: #FFFD00; }
  .mat-checkbox-checkmark-path { stroke: #292F36 !important; }
  .mat-checkbox-label { font-weight: 400; color: #4D5865; font-size: 14px; }
}

.circle-large-checkbox {
  .mat-checkbox-frame { border-color: #007C89; border-radius: 100%; border-width: 2px; }
  &.mat-checkbox-indeterminate.mat-accent .mat-checkbox-background, &.mat-checkbox-checked.mat-accent .mat-checkbox-background { background-color: #007C89; border-radius: 100%; }
  &.mat-checkbox-checked:not(.mat-checkbox-disabled).mat-accent .mat-ripple-element, &.mat-checkbox:active:not(.mat-checkbox-disabled).mat-accent .mat-ripple-element  { background: #007C89; }
  .mat-checkbox-checkmark-path { stroke: #fff !important; }
  .mat-checkbox-inner-container { height: 24px; width: 24px; }
}

// slider
.yellow-slider.mat-slider-horizontal {
  & { padding: 0px; }
  .mat-slider-wrapper, .mat-slider-track-wrapper, .mat-slider-track-background, .mat-slider-ticks-container, .mat-slider-ticks { height: 4px; }
  .mat-slider-track-fill { height: 6px; }
  .mat-slider-track-fill, .mat-slider-thumb, .mat-slider-thumb-label { background-color: #FFDB15; }
  .mat-slider-thumb { width: 30px; height: 30px; bottom: -15px; }
  .mat-slider-track-background { background-color: #FFF7AD; }
  .mat-slider-track-wrapper { border-radius: 4px; }
}

// =================== Global CSS ===================

// =================== Header ===================

.header {
  & { position: fixed; background: #fff; z-index: 99; }
  .header-toolbar {
    & { padding: 0; justify-content: space-between; }
    // logo
    img.logo { margin-top: -10px; }
    // search
    .mat-form-field {
     & {  background: $formbg; width: 320px; border-radius: 5px; }
     .mat-form-field-wrapper { margin: 0; padding: 0; }
     .mat-form-field-infix { padding: 2px 0 14px 0; border-top-width: 10px; }
     .mat-form-field-outline-start, .mat-form-field-outline-end { border-width: 0; }
      input , input::placeholder { font-size: 14px; color: $formtext; font-family: $mulish; }
      .input-search { margin-right: 5px; }
    }
    // header right
    .header-right {
      & { display: flex; align-items: center; flex-wrap: wrap; }
     img { margin: 0px 12px; cursor: pointer; }
    }
  }
  .full-frame { width: calc(100% - 140px); }
}
.header-spacer { padding-top: 64px; }
.public-header {
  & { text-align: right; margin-top: 30px; }
  img { width: 50px; }
}
// =================== Header ===================

// =================== layout ===================

.app-wrapper {
  & { display: flex; flex-wrap: wrap; padding-top: 30px; }
  app-menu { flex: 0 0 300px; margin-right: 15px; }
  router-outlet + * { flex: 1 0 auto; }
}

// ========== menu ==========
.layout-menu {
  & { width: 250px; }
  .user-info {
    & { display: flex; flex-wrap: wrap; align-items: center; border-bottom: 1px solid #C6C6C6; padding-bottom: 15px; }
    img { max-width: 52px; border-radius: 40%; }
    .user-name { margin-left: 10px; font-size: 14px; flex: 1 0 auto; }
    .menu-icon {
      & { display: flex; flex-wrap: wrap; cursor: pointer; }
      span { width: 3px; height: 3px; border-radius: 100%; border: 1px solid #3483FA; margin: 0px 1px; }
    }
  }
  .smiler-menu {
    & { margin-top: 15px; }
    .menu-item {
      & { display: flex; flex-wrap: wrap; align-items: center; margin: 15px 0px; cursor: pointer; }
      .menu-text { margin-left: 15px; color:#656565; font-size: 16px; flex: 1 0 auto; }
      .menu-icon { display: flex; align-items: center; justify-content: center; width: 45px; height: 45px; border-radius: 100%; background: linear-gradient(180deg, rgba(255, 255, 255, 0.4) 0%, rgba(255, 255, 255, 0.2) 100%); box-shadow: 0px 12px 40px rgba(202, 202, 238, 0.4); }
      .menu-notifications { color: #1F4E96; font-size: 12px; background-color: #FFFD00; padding: 2px 8px; border-radius: 10px; font-weight: 700; }
      &.active {
        .menu-icon { background: linear-gradient(90deg, #FFFD00 0%, #FFFFB3 100%); box-shadow: 0px 8px 16px rgba(255, 253, 0, 0.3); }
        .menu-text { font-weight: 600; color: #323232; }
      }
    }
  }
}
// ========== menu ==========

// =================== layout ===================

// =================== login ===================

// =================== login ===================

// =================== register ===================
.smiler-register {
  & { display: flex; flex-wrap: wrap; align-items: center; margin-bottom: 60px; }
  .register-left {
    & { flex: 0 0 45%; margin-top: -60px; }
    .register-wrapper { box-shadow:inset 0px 20px 50px rgba(222, 226 ,230 ,0.25); border-radius: 24px; background-color: #fff; padding: 60px 60px; }
    .form-heading { color: #718194; font-size: 32px; font-weight: 600; line-height: 32px; }
    .form-wrapper {
      & { margin: 70px 0px 0px; }
      form { margin-bottom: 0px; }
    }
    .yellowBtn { margin-top: 30px; }
    .name-wrapper {
      & { display: flex; flex-wrap: wrap; }
      .form-field { flex: 0 0 calc(50% - 5px); width: calc(50% - 5px); }
      .first-name { margin-right: 5px; }
      .last-name { margin-left: 5px; }
    }
  }
  .register-right {
    & { flex: 0 0 55%; text-align: center; }
    .right-title { color: #4D5865; font-size: 20px; font-weight: 300; }
  }
}
// =================== register ===================

// ========================= Smiler CSS ============================== //


